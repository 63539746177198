import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f8ad10a = () => interopDefault(import('../pages/removed.vue' /* webpackChunkName: "W3LeRb4xd2U9" */))
const _0a952b56 = () => interopDefault(import('../pages/map/used-cars/regions-and-models.vue' /* webpackChunkName: "hv50mSIC3EO9" */))
const _2eab20da = () => interopDefault(import('../pages/map/used-cars/regions-and-city-and-marks.vue' /* webpackChunkName: "zXoZkqnlQDXG" */))
const _ff0f11f2 = () => interopDefault(import('../pages/map/used-cars/regions.vue' /* webpackChunkName: "OO44paIqKaDw" */))
const _536d05a2 = () => interopDefault(import('../pages/map/used-cars/marks-and-models.vue' /* webpackChunkName: "zvM0swlfNL7J" */))
const _64001a2a = () => interopDefault(import('../pages/map/used-cars/marks-and-model-and-gearbox-type.vue' /* webpackChunkName: "VUSfPXhhzi6g" */))
const _64f1b0d5 = () => interopDefault(import('../pages/map/used-cars/marks-and-model-and-fuel.vue' /* webpackChunkName: "8xhi1imlOqB0" */))
const _5224d2ce = () => interopDefault(import('../pages/map/used-cars/marks.vue' /* webpackChunkName: "cOCyhjRh6ZEY" */))
const _5b783f71 = () => interopDefault(import('../pages/map/used-cars/cities.vue' /* webpackChunkName: "klr81bfEV5V6" */))
const _0a06be78 = () => interopDefault(import('../pages/map/used-cars/categories.vue' /* webpackChunkName: "AuLAlNRFyYWa" */))
const _7d9aaa02 = () => interopDefault(import('../pages/map/used-cars/bodyTypes.vue' /* webpackChunkName: "2vL1X0luRln8" */))
const _58f4825a = () => interopDefault(import('../pages/map/used-cars/index.vue' /* webpackChunkName: "bxmsHBIi7YOd" */))
const _416b217d = () => interopDefault(import('../pages/map/traders/regions.vue' /* webpackChunkName: "4RHxsWgxvODM" */))
const _518ba2c4 = () => interopDefault(import('../pages/map/traders/marks.vue' /* webpackChunkName: "XYNi5Gsq7j4Q" */))
const _585b5250 = () => interopDefault(import('../pages/map/traders/index.vue' /* webpackChunkName: "mBRtU7I1c4tD" */))
const _b58e2686 = () => interopDefault(import('../pages/map/news/tags.vue' /* webpackChunkName: "UEkxvlRPukh2" */))
const _36beb95e = () => interopDefault(import('../pages/map/news/index.vue' /* webpackChunkName: "jxpH23hB65Lx" */))
const _6d4e5259 = () => interopDefault(import('../pages/map/new-cars/regions-and-models.vue' /* webpackChunkName: "UDBuCVtdlklv" */))
const _78f9f5e3 = () => interopDefault(import('../pages/map/new-cars/regions-and-city-and-models.vue' /* webpackChunkName: "zVCkaFcCOO6A" */))
const _781688f2 = () => interopDefault(import('../pages/map/new-cars/marks-and-models.vue' /* webpackChunkName: "UcSAP5FIipiN" */))
const _ec898026 = () => interopDefault(import('../pages/map/new-cars/marks-and-model-and-gearbox-type.vue' /* webpackChunkName: "KaXCL39XnDxK" */))
const _6ab76cd0 = () => interopDefault(import('../pages/map/new-cars/marks-and-model-and-fuel.vue' /* webpackChunkName: "FA1ZrytW151L" */))
const _76a982eb = () => interopDefault(import('../pages/map/new-cars/marks.vue' /* webpackChunkName: "4C0Qtrj8fAQW" */))
const _70ecda18 = () => interopDefault(import('../pages/map/new-cars/cities.vue' /* webpackChunkName: "6UBoYuproukx" */))
const _e13b0a72 = () => interopDefault(import('../pages/map/new-cars/categories.vue' /* webpackChunkName: "z4ITO7ygQ9Iy" */))
const _950ff6c8 = () => interopDefault(import('../pages/map/new-cars/bodyTypes.vue' /* webpackChunkName: "TbysE00cf2Mp" */))
const _7d793277 = () => interopDefault(import('../pages/map/new-cars/index.vue' /* webpackChunkName: "yOUWHhg4hgdn" */))
const _1c8e11d5 = () => interopDefault(import('../pages/map/header.vue' /* webpackChunkName: "DHTlg8xFk3Yv" */))
const _7c269a7c = () => interopDefault(import('../pages/map/dealer-centers/regions.vue' /* webpackChunkName: "UomgnA096jTc" */))
const _ed4a7d6e = () => interopDefault(import('../pages/map/dealer-centers/marks.vue' /* webpackChunkName: "n1kGW7euYpWX" */))
const _dfab1e56 = () => interopDefault(import('../pages/map/dealer-centers/index.vue' /* webpackChunkName: "bCfwHFPGi5et" */))
const _13931d8c = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "mICn5cCDWdJN" */))
const _008cf9c4 = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "rDilrAOcbnet" */))
const _5b85561f = () => interopDefault(import('../pages/admin/guarded.vue' /* webpackChunkName: "LpZtmhWOopKt" */))
const _914aed3c = () => interopDefault(import('../pages/admin/guarded/index.vue' /* webpackChunkName: "IRCe81cE2Mqm" */))
const _062c91bd = () => interopDefault(import('../pages/admin/guarded/archived-cars/index.vue' /* webpackChunkName: "PUXwrbifVFFb" */))
const _46238fef = () => interopDefault(import('../pages/admin/guarded/brands/index.vue' /* webpackChunkName: "mF1e8yNpKGuB" */))
const _47daced0 = () => interopDefault(import('../pages/admin/guarded/car-categories/index.vue' /* webpackChunkName: "Od0BjYLQGAo7" */))
const _c2f26600 = () => interopDefault(import('../pages/admin/guarded/car-classes/index.vue' /* webpackChunkName: "VTk0Ns6oyzNR" */))
const _25a93c4b = () => interopDefault(import('../pages/admin/guarded/complaints/index.vue' /* webpackChunkName: "6nstg355Ziuf" */))
const _a4a1a894 = () => interopDefault(import('../pages/admin/guarded/contacts/index.vue' /* webpackChunkName: "DqBh0WIXeR7b" */))
const _4afe29e6 = () => interopDefault(import('../pages/admin/guarded/dealers/index.vue' /* webpackChunkName: "vZ0SWHtT0k1i" */))
const _940fe0b0 = () => interopDefault(import('../pages/admin/guarded/external-banners/index.vue' /* webpackChunkName: "jpDhCaAeWeXa" */))
const _627c6421 = () => interopDefault(import('../pages/admin/guarded/feedbacks/index.vue' /* webpackChunkName: "ymjgJjYBgFbV" */))
const _4301abc5 = () => interopDefault(import('../pages/admin/guarded/financial-services/index.vue' /* webpackChunkName: "WvyQjKqCqr5o" */))
const _5842785c = () => interopDefault(import('../pages/admin/guarded/header-links/index.vue' /* webpackChunkName: "GXavyTm69UR4" */))
const _23606392 = () => interopDefault(import('../pages/admin/guarded/metadata/index.vue' /* webpackChunkName: "0evNsuJXFUod" */))
const _03e0cbba = () => interopDefault(import('../pages/admin/guarded/moderating/index.vue' /* webpackChunkName: "NsJjFRrchXXu" */))
const _76a90b94 = () => interopDefault(import('../pages/admin/guarded/news/index.vue' /* webpackChunkName: "dkoiwtDOU0YJ" */))
const _c7687614 = () => interopDefault(import('../pages/admin/guarded/promotion/index.vue' /* webpackChunkName: "GWrEoqb2DcXY" */))
const _11f85cc8 = () => interopDefault(import('../pages/admin/guarded/sales-leaders/index.vue' /* webpackChunkName: "cyYMl7zsWjY7" */))
const _2dced3d1 = () => interopDefault(import('../pages/admin/guarded/sections/index.vue' /* webpackChunkName: "VD8Dbh220449" */))
const _218d7f3a = () => interopDefault(import('../pages/admin/guarded/setting/index.vue' /* webpackChunkName: "pkNifvkLIaAR" */))
const _501f0106 = () => interopDefault(import('../pages/admin/guarded/sms-logs/index.vue' /* webpackChunkName: "IT6WOgx8Q5Yv" */))
const _4a907808 = () => interopDefault(import('../pages/admin/guarded/tags/index.vue' /* webpackChunkName: "sO2YXdJoKvoI" */))
const _41a44fca = () => interopDefault(import('../pages/admin/guarded/users/index.vue' /* webpackChunkName: "gtsbpCQvya23" */))
const _08bc881c = () => interopDefault(import('../pages/admin/guarded/dealers/add.vue' /* webpackChunkName: "4wUei7HIauQz" */))
const _7af110d6 = () => interopDefault(import('../pages/admin/guarded/equipments/new.vue' /* webpackChunkName: "bCd4Zks8QBdw" */))
const _24337380 = () => interopDefault(import('../pages/admin/guarded/import/ria.vue' /* webpackChunkName: "f7K6YFINcJUm" */))
const _46c6d110 = () => interopDefault(import('../pages/admin/guarded/modifications/new.vue' /* webpackChunkName: "20ZHWPIVe9DN" */))
const _0362cd85 = () => interopDefault(import('../pages/admin/guarded/news/add.vue' /* webpackChunkName: "822gRhRd2RQW" */))
const _249e092f = () => interopDefault(import('../pages/admin/guarded/news/authors/index.vue' /* webpackChunkName: "Fdil1OTr5OnL" */))
const _2df1402c = () => interopDefault(import('../pages/admin/guarded/sales-leaders/new.vue' /* webpackChunkName: "cXO0zxMOpcIf" */))
const _87a37f1a = () => interopDefault(import('../pages/admin/guarded/statistic/export.vue' /* webpackChunkName: "NfyvUsX5FgPr" */))
const _2e633fe4 = () => interopDefault(import('../pages/admin/guarded/statistic/import.vue' /* webpackChunkName: "ZtdMQXvgpq52" */))
const _6a3d60aa = () => interopDefault(import('../pages/admin/guarded/users/add.vue' /* webpackChunkName: "XqexTbKBYznU" */))
const _13227284 = () => interopDefault(import('../pages/admin/guarded/news/authors/add.vue' /* webpackChunkName: "5CSt76QstHHr" */))
const _5dec0cda = () => interopDefault(import('../pages/admin/guarded/equipments/copy/_id.vue' /* webpackChunkName: "Cz6vbffw0hRk" */))
const _d5aae952 = () => interopDefault(import('../pages/admin/guarded/news/authors/_id.vue' /* webpackChunkName: "WMhzIzlqKhrY" */))
const _f83a7bd2 = () => interopDefault(import('../pages/admin/guarded/brands/_id.vue' /* webpackChunkName: "mgYr0cqyA5dQ" */))
const _3c1de100 = () => interopDefault(import('../pages/admin/guarded/car-categories/_id.vue' /* webpackChunkName: "i4CwpbUprUpA" */))
const _b10f6696 = () => interopDefault(import('../pages/admin/guarded/dealers/_id.vue' /* webpackChunkName: "bykmgvUe6EIo" */))
const _673b3bf0 = () => interopDefault(import('../pages/admin/guarded/equipments/_id.vue' /* webpackChunkName: "1bzi1NNajttX" */))
const _4eb4f496 = () => interopDefault(import('../pages/admin/guarded/generations/_id.vue' /* webpackChunkName: "72xrth6dA4X6" */))
const _3876227a = () => interopDefault(import('../pages/admin/guarded/metadata/_id.vue' /* webpackChunkName: "ZHZ3bVDiyOg3" */))
const _2b90ee96 = () => interopDefault(import('../pages/admin/guarded/models/_id.vue' /* webpackChunkName: "wjagA6KOJ3Tt" */))
const _6e327adc = () => interopDefault(import('../pages/admin/guarded/modifications/_id.vue' /* webpackChunkName: "FjAXvyTyyaTt" */))
const _bbc2dbc4 = () => interopDefault(import('../pages/admin/guarded/news/_id.vue' /* webpackChunkName: "6vVmpdZm484L" */))
const _555ce9f8 = () => interopDefault(import('../pages/admin/guarded/sales-leaders/_id.vue' /* webpackChunkName: "DCWhg8UWLHES" */))
const _14d8807c = () => interopDefault(import('../pages/admin/guarded/series/_id.vue' /* webpackChunkName: "TaWiWp29yCDV" */))
const _08f92543 = () => interopDefault(import('../pages/admin/guarded/users/_id.vue' /* webpackChunkName: "HYpUW1a3kkp3" */))
const _29d3d5d3 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "XAvx3Ing1BNP" */))
const _2562a000 = () => interopDefault(import('../pages/client.vue' /* webpackChunkName: "Mv7VZqXzXXst" */))
const _d15192fa = () => interopDefault(import('../pages/client/index.vue' /* webpackChunkName: "pshgU7GlJWm2" */))
const _62e6a9bd = () => interopDefault(import('../pages/client/about-us.vue' /* webpackChunkName: "hxqJgEo9vGb9" */))
const _41522072 = () => interopDefault(import('../pages/client/advantages.vue' /* webpackChunkName: "9BXVxyQN72j4" */))
const _aa2775ec = () => interopDefault(import('../pages/client/advantages/index.vue' /* webpackChunkName: "kK2tUSe4VVjT" */))
const _4413ebb8 = () => interopDefault(import('../pages/client/advantages/business.vue' /* webpackChunkName: "sy2x3moVtnk1" */))
const _9148ddf8 = () => interopDefault(import('../pages/client/banner-test.vue' /* webpackChunkName: "PNR8Up24OxxD" */))
const _7c9e816b = () => interopDefault(import('../pages/client/brands.vue' /* webpackChunkName: "6xN1pQeuUNUJ" */))
const _7a360c80 = () => interopDefault(import('../pages/client/certification-info.vue' /* webpackChunkName: "LdXIlxiPA8iN" */))
const _2b030a9c = () => interopDefault(import('../pages/client/contacts.vue' /* webpackChunkName: "7DeRUnaWJJtd" */))
const _77599e62 = () => interopDefault(import('../pages/client/cookies-policy.vue' /* webpackChunkName: "PQcn58Ol3h6C" */))
const _60fed99a = () => interopDefault(import('../pages/client/login.vue' /* webpackChunkName: "yuQRzMxF59S2" */))
const _766d5e8a = () => interopDefault(import('../pages/client/password-recovery/index.vue' /* webpackChunkName: "1wrl1oKc1h1T" */))
const _3d09e570 = () => interopDefault(import('../pages/client/personal-data.vue' /* webpackChunkName: "P38kMYXobffu" */))
const _f21dab94 = () => interopDefault(import('../pages/client/privacy-policy.vue' /* webpackChunkName: "0FYp3Y7JEdxY" */))
const _17dbb19a = () => interopDefault(import('../pages/client/profile.vue' /* webpackChunkName: "oAJevvVJltEw" */))
const _c641c6c6 = () => interopDefault(import('../pages/client/profile/index.vue' /* webpackChunkName: "YItKabhm4eRE" */))
const _6ae6f1a4 = () => interopDefault(import('../pages/client/profile/add-advertisement.vue' /* webpackChunkName: "tuDKhj8tcc9G" */))
const _28e49d04 = () => interopDefault(import('../pages/client/profile/add-advertisement-old.vue' /* webpackChunkName: "ntCIBC0Rzce2" */))
const _279f2b2a = () => interopDefault(import('../pages/client/profile/become-dealer.vue' /* webpackChunkName: "GnYVmz3VtMko" */))
const _33cac851 = () => interopDefault(import('../pages/client/profile/car-reports.vue' /* webpackChunkName: "IgBUMbQYMAuy" */))
const _e1e84064 = () => interopDefault(import('../pages/client/profile/comparison.vue' /* webpackChunkName: "zngJJ3spPDTn" */))
const _2106e85e = () => interopDefault(import('../pages/client/profile/dealer.vue' /* webpackChunkName: "qtCIVQCxfyWB" */))
const _e98051ee = () => interopDefault(import('../pages/client/profile/dealer/_id.vue' /* webpackChunkName: "H9PTziAzZkaf" */))
const _2ba732cc = () => interopDefault(import('../pages/client/profile/dealer/_id/index.vue' /* webpackChunkName: "sj1VsJ5fcnA9" */))
const _5750663a = () => interopDefault(import('../pages/client/profile/dealer/_id/actions/index.vue' /* webpackChunkName: "ynLJMd2aiGuS" */))
const _1a4c3953 = () => interopDefault(import('../pages/client/profile/dealer/_id/add-advertisement.vue' /* webpackChunkName: "MRr1Qa1eU2w4" */))
const _64853200 = () => interopDefault(import('../pages/client/profile/dealer/_id/edit.vue' /* webpackChunkName: "vgtGBFeXHzFR" */))
const _2110c713 = () => interopDefault(import('../pages/client/profile/dealer/_id/members.vue' /* webpackChunkName: "BD3ov5e9tocL" */))
const _0e027356 = () => interopDefault(import('../pages/client/profile/dealer/_id/members/index.vue' /* webpackChunkName: "2ZSy4PyhsZcV" */))
const _02658ea5 = () => interopDefault(import('../pages/client/profile/dealer/_id/members/add.vue' /* webpackChunkName: "7ts5ETkHBsJT" */))
const _a665eba0 = () => interopDefault(import('../pages/client/profile/dealer/_id/members/index-old.vue' /* webpackChunkName: "IZuipyQE1DBA" */))
const _2600d5bc = () => interopDefault(import('../pages/client/profile/dealer/_id/test-drive-requests.vue' /* webpackChunkName: "2wrvfLDiDoKE" */))
const _78ca2aee = () => interopDefault(import('../pages/client/profile/dealer/_id/actions/add.vue' /* webpackChunkName: "4ixPxLFkfjeg" */))
const _d7e12c90 = () => interopDefault(import('../pages/client/profile/dealer/_id/actions/_actionId.vue' /* webpackChunkName: "EwONpJXNobpX" */))
const _45ce9ef9 = () => interopDefault(import('../pages/client/profile/dealer-created.vue' /* webpackChunkName: "eXqu8Nz0091p" */))
const _3c2f01e2 = () => interopDefault(import('../pages/client/profile/edit.vue' /* webpackChunkName: "nkVtHWwM7iII" */))
const _7a36dd2e = () => interopDefault(import('../pages/client/profile/edit-old.vue' /* webpackChunkName: "eMef2YC1clyo" */))
const _8779742c = () => interopDefault(import('../pages/client/profile/favorite-advertisements.vue' /* webpackChunkName: "wpogEjHVVjVx" */))
const _75222b62 = () => interopDefault(import('../pages/client/profile/favorite-filters.vue' /* webpackChunkName: "R3zO7wt20jG2" */))
const _8a628466 = () => interopDefault(import('../pages/client/profile/test-drive-requests.vue' /* webpackChunkName: "Wf7nwsVBDRYl" */))
const _25c5c47c = () => interopDefault(import('../pages/client/profile/edit-advertisement/_slug.vue' /* webpackChunkName: "s87nCAv1n2fX" */))
const _0e732929 = () => interopDefault(import('../pages/client/profile/promotion/_slug.vue' /* webpackChunkName: "VmjrE8iLI5wL" */))
const _69f7f6df = () => interopDefault(import('../pages/client/protected.vue' /* webpackChunkName: "q7XAxnkgowp0" */))
const _e54828d0 = () => interopDefault(import('../pages/client/recaptcha.vue' /* webpackChunkName: "QQh9eFw492PL" */))
const _3855c8cc = () => interopDefault(import('../pages/client/recommended.vue' /* webpackChunkName: "vX1GHsHjRmrk" */))
const _4b4a6498 = () => interopDefault(import('../pages/client/registration.vue' /* webpackChunkName: "RB0i2dRdrbK5" */))
const _4ead161e = () => interopDefault(import('../pages/client/registration-dealer.vue' /* webpackChunkName: "cLuTmSusaQZb" */))
const _905ef988 = () => interopDefault(import('../pages/client/ui-kit.vue' /* webpackChunkName: "BCDdrQHSl8Xf" */))
const _7c1c489d = () => interopDefault(import('../pages/client/anon/comparison.vue' /* webpackChunkName: "Q4xxQcPXzzWb" */))
const _7feb754b = () => interopDefault(import('../pages/client/anon/favorite-list.vue' /* webpackChunkName: "byAYcS0q9kiY" */))
const _51f9f0ea = () => interopDefault(import('../pages/client/final-pages/thank-you.vue' /* webpackChunkName: "BHGBFQoK4fZH" */))
const _b074270a = () => interopDefault(import('../pages/client/available-models/_query.vue' /* webpackChunkName: "MBTcIpf25ikb" */))
const _7ea05897 = () => interopDefault(import('../pages/client/car-report/_id.vue' /* webpackChunkName: "D9Ox9WsRWosm" */))
const _549f9a59 = () => interopDefault(import('../pages/client/car-short-link/_short.vue' /* webpackChunkName: "HZjaKY4Rricg" */))
const _2a256c40 = () => interopDefault(import('../pages/client/car/_slug.vue' /* webpackChunkName: "YiDrifa6D3xX" */))
const _ae807a7a = () => interopDefault(import('../pages/client/car/_slug/index.vue' /* webpackChunkName: "AX6QaG5zbl2O" */))
const _b353afba = () => interopDefault(import('../pages/client/car/_slug/photo.vue' /* webpackChunkName: "vfxMjj30GqDQ" */))
const _2854b1ec = () => interopDefault(import('../pages/client/car/_slug/video.vue' /* webpackChunkName: "UZHkuTivqbuO" */))
const _2aabccb2 = () => interopDefault(import('../pages/client/cities/_type.vue' /* webpackChunkName: "MddwB6rbaV2Y" */))
const _bf9e97c2 = () => interopDefault(import('../pages/client/dealer-centers/_query.vue' /* webpackChunkName: "Fu5Rh7zyu5Lt" */))
const _25025e1a = () => interopDefault(import('../pages/client/detail-search/_query.vue' /* webpackChunkName: "j6ccSpzwwUTx" */))
const _29b8a500 = () => interopDefault(import('../pages/client/full-news/_slug/index.vue' /* webpackChunkName: "b33wFanDXwUU" */))
const _7d6315b4 = () => interopDefault(import('../pages/client/news-author/_slug/index.vue' /* webpackChunkName: "MgEAs5fN2u7k" */))
const _3d1e53f6 = () => interopDefault(import('../pages/client/news/_query.vue' /* webpackChunkName: "Il9tTJfaARYz" */))
const _5a285e61 = () => interopDefault(import('../pages/client/password-recovery/_token.vue' /* webpackChunkName: "8uweumZJgRrW" */))
const _056acc01 = () => interopDefault(import('../pages/client/promotion-advertisement-success-payment/_short.vue' /* webpackChunkName: "Muiv2eN13b8W" */))
const _76ebf24f = () => interopDefault(import('../pages/client/republish/_short.vue' /* webpackChunkName: "pQSXqMZUoysp" */))
const _d6d9e8dc = () => interopDefault(import('../pages/client/traders/_query.vue' /* webpackChunkName: "dXWp3SivwIsb" */))
const _6ccbf9d6 = () => interopDefault(import('../pages/client/dealer/_slug/_query.vue' /* webpackChunkName: "JLfI4bgoKNMH" */))
const _623eec26 = () => interopDefault(import('../pages/client/seller/_code/_query.vue' /* webpackChunkName: "ozRP5qs51WlN" */))
const _046f5e40 = () => interopDefault(import('../pages/client/trader/_slug/_query.vue' /* webpackChunkName: "t4PcglXcHt3B" */))
const _67f99f5e = () => interopDefault(import('../pages/client/_type/_query.vue' /* webpackChunkName: "nMce1PwcfSQk" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/removed",
    component: _8f8ad10a,
    name: "removed"
  }, {
    path: "/map/used-cars/regions-and-models",
    component: _0a952b56,
    name: "map-used-cars-regions-and-models"
  }, {
    path: "/map/used-cars/regions-and-city-and-marks",
    component: _2eab20da,
    name: "map-used-cars-regions-and-city-and-marks"
  }, {
    path: "/map/used-cars/regions",
    component: _ff0f11f2,
    name: "map-used-cars-regions"
  }, {
    path: "/map/used-cars/marks-and-models",
    component: _536d05a2,
    name: "map-used-cars-marks-and-models"
  }, {
    path: "/map/used-cars/marks-and-model-and-gearbox-type",
    component: _64001a2a,
    name: "map-used-cars-marks-and-model-and-gearbox-type"
  }, {
    path: "/map/used-cars/marks-and-model-and-fuel",
    component: _64f1b0d5,
    name: "map-used-cars-marks-and-model-and-fuel"
  }, {
    path: "/map/used-cars/marks",
    component: _5224d2ce,
    name: "map-used-cars-marks"
  }, {
    path: "/map/used-cars/cities",
    component: _5b783f71,
    name: "map-used-cars-cities"
  }, {
    path: "/map/used-cars/categories",
    component: _0a06be78,
    name: "map-used-cars-categories"
  }, {
    path: "/map/used-cars/bodyTypes",
    component: _7d9aaa02,
    name: "map-used-cars-bodyTypes"
  }, {
    path: "/map/used-cars",
    component: _58f4825a,
    name: "map-used-cars"
  }, {
    path: "/map/traders/regions",
    component: _416b217d,
    name: "map-traders-regions"
  }, {
    path: "/map/traders/marks",
    component: _518ba2c4,
    name: "map-traders-marks"
  }, {
    path: "/map/traders",
    component: _585b5250,
    name: "map-traders"
  }, {
    path: "/map/news/tags",
    component: _b58e2686,
    name: "map-news-tags"
  }, {
    path: "/map/news",
    component: _36beb95e,
    name: "map-news"
  }, {
    path: "/map/new-cars/regions-and-models",
    component: _6d4e5259,
    name: "map-new-cars-regions-and-models"
  }, {
    path: "/map/new-cars/regions-and-city-and-models",
    component: _78f9f5e3,
    name: "map-new-cars-regions-and-city-and-models"
  }, {
    path: "/map/new-cars/marks-and-models",
    component: _781688f2,
    name: "map-new-cars-marks-and-models"
  }, {
    path: "/map/new-cars/marks-and-model-and-gearbox-type",
    component: _ec898026,
    name: "map-new-cars-marks-and-model-and-gearbox-type"
  }, {
    path: "/map/new-cars/marks-and-model-and-fuel",
    component: _6ab76cd0,
    name: "map-new-cars-marks-and-model-and-fuel"
  }, {
    path: "/map/new-cars/marks",
    component: _76a982eb,
    name: "map-new-cars-marks"
  }, {
    path: "/map/new-cars/cities",
    component: _70ecda18,
    name: "map-new-cars-cities"
  }, {
    path: "/map/new-cars/categories",
    component: _e13b0a72,
    name: "map-new-cars-categories"
  }, {
    path: "/map/new-cars/bodyTypes",
    component: _950ff6c8,
    name: "map-new-cars-bodyTypes"
  }, {
    path: "/map/new-cars",
    component: _7d793277,
    name: "map-new-cars"
  }, {
    path: "/map/header",
    component: _1c8e11d5,
    name: "map-header"
  }, {
    path: "/map/dealer-centers/regions",
    component: _7c269a7c,
    name: "map-dealer-centers-regions"
  }, {
    path: "/map/dealer-centers/marks",
    component: _ed4a7d6e,
    name: "map-dealer-centers-marks"
  }, {
    path: "/map/dealer-centers",
    component: _dfab1e56,
    name: "map-dealer-centers"
  }, {
    path: "/map",
    component: _13931d8c,
    name: "map"
  }, {
    path: "/881b6124-9fe2-4a69-a6c1-f391611b199d/login",
    component: _008cf9c4,
    name: "admin-login"
  }, {
    path: "/881b6124-9fe2-4a69-a6c1-f391611b199d",
    component: _5b85561f,
    children: [{
      path: "",
      component: _914aed3c,
      name: "admin"
    }, {
      path: "archived-cars",
      component: _062c91bd,
      name: "admin-archived-cars"
    }, {
      path: "brands",
      component: _46238fef,
      name: "admin-brands"
    }, {
      path: "car-categories",
      component: _47daced0,
      name: "admin-car-categories"
    }, {
      path: "car-classes",
      component: _c2f26600,
      name: "admin-car-classes"
    }, {
      path: "complaints",
      component: _25a93c4b,
      name: "admin-complaints"
    }, {
      path: "contacts",
      component: _a4a1a894,
      name: "admin-contacts"
    }, {
      path: "dealers",
      component: _4afe29e6,
      name: "admin-dealers"
    }, {
      path: "external-banners",
      component: _940fe0b0,
      name: "admin-external-banners"
    }, {
      path: "feedbacks",
      component: _627c6421,
      name: "admin-feedbacks"
    }, {
      path: "financial-services",
      component: _4301abc5,
      name: "admin-financial-services"
    }, {
      path: "header-links",
      component: _5842785c,
      name: "admin-header-links"
    }, {
      path: "metadata",
      component: _23606392,
      name: "admin-metadata"
    }, {
      path: "moderating",
      component: _03e0cbba,
      name: "admin-moderating"
    }, {
      path: "news",
      component: _76a90b94,
      name: "admin-news"
    }, {
      path: "promotion",
      component: _c7687614,
      name: "admin-promotion"
    }, {
      path: "sales-leaders",
      component: _11f85cc8,
      name: "admin-sales-leaders"
    }, {
      path: "sections",
      component: _2dced3d1,
      name: "admin-sections"
    }, {
      path: "setting",
      component: _218d7f3a,
      name: "admin-setting"
    }, {
      path: "sms-logs",
      component: _501f0106,
      name: "admin-sms-logs"
    }, {
      path: "tags",
      component: _4a907808,
      name: "admin-tags"
    }, {
      path: "users",
      component: _41a44fca,
      name: "admin-users"
    }, {
      path: "dealers/add",
      component: _08bc881c,
      name: "admin-dealers-add"
    }, {
      path: "equipments/new",
      component: _7af110d6,
      name: "admin-equipments-new"
    }, {
      path: "import/ria",
      component: _24337380,
      name: "admin-import-ria"
    }, {
      path: "modifications/new",
      component: _46c6d110,
      name: "admin-modifications-new"
    }, {
      path: "news/add",
      component: _0362cd85,
      name: "admin-news-add"
    }, {
      path: "news/authors",
      component: _249e092f,
      name: "admin-news-authors"
    }, {
      path: "sales-leaders/new",
      component: _2df1402c,
      name: "admin-sales-leaders-new"
    }, {
      path: "statistic/export",
      component: _87a37f1a,
      name: "admin-statistic-export"
    }, {
      path: "statistic/import",
      component: _2e633fe4,
      name: "admin-statistic-import"
    }, {
      path: "users/add",
      component: _6a3d60aa,
      name: "admin-users-add"
    }, {
      path: "news/authors/add",
      component: _13227284,
      name: "admin-news-authors-add"
    }, {
      path: "equipments/copy/:id?",
      component: _5dec0cda,
      name: "admin-equipments-copy-id"
    }, {
      path: "news/authors/:id",
      component: _d5aae952,
      name: "admin-news-authors-id"
    }, {
      path: "brands/:id",
      component: _f83a7bd2,
      name: "admin-brands-id"
    }, {
      path: "car-categories/:id?",
      component: _3c1de100,
      name: "admin-car-categories-id"
    }, {
      path: "dealers/:id",
      component: _b10f6696,
      name: "admin-dealers-id"
    }, {
      path: "equipments/:id?",
      component: _673b3bf0,
      name: "admin-equipments-id"
    }, {
      path: "generations/:id?",
      component: _4eb4f496,
      name: "admin-generations-id"
    }, {
      path: "metadata/:id",
      component: _3876227a,
      name: "admin-metadata-id"
    }, {
      path: "models/:id?",
      component: _2b90ee96,
      name: "admin-models-id"
    }, {
      path: "modifications/:id?",
      component: _6e327adc,
      name: "admin-modifications-id"
    }, {
      path: "news/:id",
      component: _bbc2dbc4,
      name: "admin-news-id"
    }, {
      path: "sales-leaders/:id?",
      component: _555ce9f8,
      name: "admin-sales-leaders-id"
    }, {
      path: "series/:id?",
      component: _14d8807c,
      name: "admin-series-id"
    }, {
      path: "users/:id",
      component: _08f92543,
      name: "admin-users-id"
    }]
  }, {
    path: "/404",
    component: _29d3d5d3,
    name: "404"
  }, {
    path: "",
    component: _2562a000,
    children: [{
      path: "",
      component: _d15192fa,
      name: "client"
    }, {
      path: "about-us",
      component: _62e6a9bd,
      name: "client-about-us"
    }, {
      path: "advantages",
      component: _41522072,
      children: [{
        path: "",
        component: _aa2775ec,
        name: "client-advantages"
      }, {
        path: "business",
        component: _4413ebb8,
        name: "client-advantages-business"
      }]
    }, {
      path: "banner-test",
      component: _9148ddf8,
      name: "client-banner-test"
    }, {
      path: "brands",
      component: _7c9e816b,
      name: "client-brands"
    }, {
      path: "certification-info",
      component: _7a360c80,
      name: "client-certification-info"
    }, {
      path: "contacts",
      component: _2b030a9c,
      name: "client-contacts"
    }, {
      path: "cookies-policy",
      component: _77599e62,
      name: "client-cookies-policy"
    }, {
      path: "login",
      component: _60fed99a,
      name: "client-login"
    }, {
      path: "password-recovery",
      component: _766d5e8a,
      name: "client-password-recovery"
    }, {
      path: "personal-data",
      component: _3d09e570,
      name: "client-personal-data"
    }, {
      path: "privacy-policy",
      component: _f21dab94,
      name: "client-privacy-policy"
    }, {
      path: "profile",
      component: _17dbb19a,
      children: [{
        path: "",
        component: _c641c6c6,
        name: "client-profile"
      }, {
        path: "add-advertisement",
        component: _6ae6f1a4,
        name: "client-profile-add-advertisement"
      }, {
        path: "add-advertisement-old",
        component: _28e49d04,
        name: "client-profile-add-advertisement-old"
      }, {
        path: "become-dealer",
        component: _279f2b2a,
        name: "client-profile-become-dealer"
      }, {
        path: "car-reports",
        component: _33cac851,
        name: "client-profile-car-reports"
      }, {
        path: "comparison",
        component: _e1e84064,
        name: "client-profile-comparison"
      }, {
        path: "dealer",
        component: _2106e85e,
        name: "client-profile-dealer",
        children: [{
          path: ":id?",
          component: _e98051ee,
          children: [{
            path: "",
            component: _2ba732cc,
            name: "client-profile-dealer-id"
          }, {
            path: "actions",
            component: _5750663a,
            name: "client-profile-dealer-id-actions"
          }, {
            path: "add-advertisement",
            component: _1a4c3953,
            name: "client-profile-dealer-id-add-advertisement"
          }, {
            path: "edit",
            component: _64853200,
            name: "client-profile-dealer-id-edit"
          }, {
            path: "members",
            component: _2110c713,
            children: [{
              path: "",
              component: _0e027356,
              name: "client-profile-dealer-id-members"
            }, {
              path: "add",
              component: _02658ea5,
              name: "client-profile-dealer-id-members-add"
            }, {
              path: "index-old",
              component: _a665eba0,
              name: "client-profile-dealer-id-members-index-old"
            }]
          }, {
            path: "test-drive-requests",
            component: _2600d5bc,
            name: "client-profile-dealer-id-test-drive-requests"
          }, {
            path: "actions/add",
            component: _78ca2aee,
            name: "client-profile-dealer-id-actions-add"
          }, {
            path: "actions/:actionId",
            component: _d7e12c90,
            name: "client-profile-dealer-id-actions-actionId"
          }]
        }]
      }, {
        path: "dealer-created",
        component: _45ce9ef9,
        name: "client-profile-dealer-created"
      }, {
        path: "edit",
        component: _3c2f01e2,
        name: "client-profile-edit"
      }, {
        path: "edit-old",
        component: _7a36dd2e,
        name: "client-profile-edit-old"
      }, {
        path: "favorite-advertisements",
        component: _8779742c,
        name: "client-profile-favorite-advertisements"
      }, {
        path: "favorite-filters",
        component: _75222b62,
        name: "client-profile-favorite-filters"
      }, {
        path: "test-drive-requests",
        component: _8a628466,
        name: "client-profile-test-drive-requests"
      }, {
        path: "edit-advertisement/:slug?",
        component: _25c5c47c,
        name: "client-profile-edit-advertisement-slug"
      }, {
        path: "promotion/:slug?",
        component: _0e732929,
        name: "client-profile-promotion-slug"
      }]
    }, {
      path: "protected",
      component: _69f7f6df,
      name: "client-protected"
    }, {
      path: "recaptcha",
      component: _e54828d0,
      name: "client-recaptcha"
    }, {
      path: "recommended",
      component: _3855c8cc,
      name: "client-recommended"
    }, {
      path: "registration",
      component: _4b4a6498,
      name: "client-registration"
    }, {
      path: "registration-dealer",
      component: _4ead161e,
      name: "client-registration-dealer"
    }, {
      path: "ui-kit",
      component: _905ef988,
      name: "client-ui-kit"
    }, {
      path: "anon/comparison",
      component: _7c1c489d,
      name: "client-anon-comparison"
    }, {
      path: "anon/favorite-list",
      component: _7feb754b,
      name: "client-anon-favorite-list"
    }, {
      path: "final-pages/thank-you",
      component: _51f9f0ea,
      name: "client-final-pages-thank-you"
    }, {
      path: "available-models/:query?",
      component: _b074270a,
      name: "client-available-models-query"
    }, {
      path: "car-report/:id?",
      component: _7ea05897,
      name: "client-car-report-id"
    }, {
      path: "c/:short?",
      component: _549f9a59,
      name: "client-car-short-link-short"
    }, {
      path: "car/:slug?",
      component: _2a256c40,
      children: [{
        path: "",
        component: _ae807a7a,
        name: "client-car-slug"
      }, {
        path: "photo",
        component: _b353afba,
        name: "client-car-slug-photo"
      }, {
        path: "video",
        component: _2854b1ec,
        name: "client-car-slug-video"
      }]
    }, {
      path: "cities/:type?",
      component: _2aabccb2,
      name: "client-cities-type"
    }, {
      path: "dealer-centers/:query?",
      component: _bf9e97c2,
      name: "client-dealer-centers-query"
    }, {
      path: "detail-search/:query?",
      component: _25025e1a,
      name: "client-detail-search-query"
    }, {
      path: "full-news/:slug",
      component: _29b8a500,
      name: "client-full-news-slug"
    }, {
      path: "news-author/:slug",
      component: _7d6315b4,
      name: "client-news-author-slug"
    }, {
      path: "news/:query?",
      component: _3d1e53f6,
      name: "client-news-query"
    }, {
      path: "password-recovery/:token?",
      component: _5a285e61,
      name: "client-password-recovery-token"
    }, {
      path: "promotion-advertisement-success-payment/:short?",
      component: _056acc01,
      name: "client-promotion-advertisement-success-payment-short"
    }, {
      path: "rep/:short?",
      component: _76ebf24f,
      name: "client-republish-short"
    }, {
      path: "traders/:query?",
      component: _d6d9e8dc,
      name: "client-traders-query"
    }, {
      path: "dealer/:slug?/:query?",
      component: _6ccbf9d6,
      name: "client-dealer-slug-query"
    }, {
      path: "seller/:code?/:query?",
      component: _623eec26,
      name: "client-seller-code-query"
    }, {
      path: "trader/:slug?/:query?",
      component: _046f5e40,
      name: "client-trader-slug-query"
    }, {
      path: "/:type/:query?/:region?/:city?/:bodyType?/:brand?/:model?/:year?/:mileage?/:price?",
      component: _67f99f5e,
      name: "client-type-query"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
